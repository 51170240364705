

text{
   fill: white !important; 
  font-size: 15px !important;
  /* font-weight: 600!important;
  color:#fff!important; */
  opacity: 1;
  /* background-color: black; */
mix-blend-mode: difference; 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;

}
#chart {
  height: 700px;
}

.title{
  align-items: center;
  text-align: center;
  color: azure;
  font-size: 20px;
}
